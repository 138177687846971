import { ProjectFormValues } from '@app/components/project/ProjectForm';
import { httpApi } from './http.api';
import { convertFormData } from '@app/utils/common';
import { resizeImage } from '@app/utils/utils';

interface ProjectResponse {
  id: number;
  project_name: string;
  project_name_eng: string;
  client_name: string;
  client_name_eng: string;
  field: string;
  field_eng: string;
  description: string;
  description_eng: string;
  project_date: string;
  img_url: string;
}

interface IResponse {
  projects: ProjectResponse[];
  total: number;
}

interface IParam {
  search: string;
  page: number;
  limit: number;
}

export const getProjectList = (params: IParam): Promise<IResponse> => {
  return httpApi
    .get<IResponse>('/project/x', {
      params,
    })
    .then(({ data }) => data);
};

export const addProject = async (file1: File, file2: File | null, file3: File | null, data: ProjectFormValues) => {
  const formData = convertFormData(data);
  const fileResize1 = await resizeImage(file1);
  formData.append('file', fileResize1);
  if (file2) {
    const fileResize2 = await resizeImage(file2);
    formData.append('file', fileResize2);
  }
  if (file3) {
    const fileResize3 = await resizeImage(file3);
    formData.append('file', fileResize3);
  }
  return httpApi.post('/project', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getProjectDetailById = (id: number): Promise<ProjectResponse> => {
  return httpApi.get<ProjectResponse>(`/project/${id}`).then(({ data }) => data);
};

export const updateProject = async (
  projectId: number,
  file1: File | null,
  file2: File | null,
  file3: File | null,
  imgEdit1: string,
  imgEdit2: string,
  imgEdit3: string,
  data: ProjectFormValues,
) => {
  const formData = convertFormData(data);
  if (file1) {
    const fileResize1 = await resizeImage(file1);
    formData.append('file1', fileResize1);
  }
  if (file2) {
    const fileResize2 = await resizeImage(file2);
    formData.append('file2', fileResize2);
  }
  if (file3) {
    const fileResize3 = await resizeImage(file3);
    formData.append('file3', fileResize3);
  }
  formData.append('imgEdit1', imgEdit1);
  formData.append('imgEdit2', imgEdit2);
  formData.append('imgEdit3', imgEdit3);

  return httpApi.put(`/project/${projectId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteProject = (projectId: number) => {
  return httpApi.delete(`/project/${projectId}`);
};
