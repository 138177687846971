import { NewsFormValues } from '@app/components/news/NewsForm';
import { convertFormData } from '@app/utils/common';
import { httpApi } from './http.api';
import { NewsRow } from '@app/components/news/NewsTable';

export interface INewsRequest extends Omit<NewsFormValues, 'keyword' | 'product_related'> {
  keyword: string;
  product_related: string;
}

interface IParam {
  search: string;
  page: number;
  limit: number;
}

export interface NewsResponse extends NewsRow {
  id: number;
}

interface IResponse {
  news: NewsResponse[];
  total: number;
}

export const getNewsDetailById = (id: number): Promise<NewsResponse> => {
  return httpApi.get<NewsResponse>(`/news/${id}`).then(({ data }) => data);
};

export const getNewsList = (params: IParam): Promise<IResponse> => {
  return httpApi
    .get<IResponse>('/news/x', {
      params,
    })
    .then(({ data }) => data);
};

export const addNews = (file: File, newsRequest: INewsRequest): Promise<boolean> => {
  const formData = convertFormData(newsRequest);

  formData.append('file', file);
  return httpApi.post('/news', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateNews = (
  newsId: number,
  file: File | null,
  newsRequest: INewsRequest,
  imgEdit: string,
): Promise<boolean> => {
  const formData = convertFormData(newsRequest);
  imgEdit && formData.append('image_url', imgEdit);
  file && formData.append('file', file);
  return httpApi.put(`/news/${newsId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateStatusNews = (newsId: number, status: number) => {
  return httpApi.put(`/news/status/${newsId}`, { active_status: status });
};

export const deleteNews = (newsId: number) => {
  return httpApi.delete(`/news/${newsId}`);
};
