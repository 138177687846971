import { deleteCategory, getCategoryList, updateStatusCategory } from '@app/api/category.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { useMounted } from '@app/hooks/useMounted';
import { ColumnsType } from 'antd/es/table';
import { Pagination } from 'api/table.api';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalCategoryEdit from './ModalCategoryEdit';
import styled from 'styled-components';
import { Popconfirm, Switch } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { BasePopconfirm } from '../common/BasePopconfirm/BasePopconfirm';

const DivStyled = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  gap: 20px;
  align-items: center;
`;

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
};

export interface CategoryRow {
  key: number;
  name: string;
  name_eng: string;
  slug: string;
  slug_eng: string;
  active_status: boolean;
}

const CategoryTable: React.FC = () => {
  const [tableData, setTableData] = useState<{ data: CategoryRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [categoryEdit, setCategoryEdit] = useState<CategoryRow | null>(null);
  const [openAdd, setOpenAdd] = useState(false);
  const [isRefetch, setIsRefetch] = useState(false);
  const { t } = useTranslation();
  const { isMounted } = useMounted();

  // Call api fetch category list
  const fetch = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getCategoryList().then((res) => {
        if (isMounted.current) {
          setTableData({
            data: res.map((item) => ({
              key: item.id,
              name: item.name,
              name_eng: item.name_eng,
              slug: item.slug,
              slug_eng: item.slug_eng,
              active_status: !!item.active_status,
            })),
            pagination: initialPagination,
            loading: false,
          });
        }
      });
      // getBasicTableData(pagination).then((res) => {
      //   if (isMounted.current) {
      //     setTableData({ data: res.data, pagination: res.pagination, loading: false });
      //   }
      // });
    },
    [isMounted],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch, isRefetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.key !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
  };

  const handleUpdateStatus = (record: CategoryRow) => {
    updateStatusCategory(record.key, !record.active_status ? 1 : 0)
      .then(() => {
        notificationController.success({
          message: 'Cập nhật danh mục thành công',
        });
        setTableData({
          ...tableData,
          data: tableData.data.map((item) =>
            item.key === record.key ? { ...item, active_status: !item.active_status } : item,
          ),
        });
      })
      .catch((err) => {
        notificationController.error({
          message: t('common.error'),
          description: err.toString(),
        });
      });
  };

  const onDelete = (categoryId: number) => {
    deleteCategory(categoryId)
      .then(() => {
        notificationController.success({ message: 'Xoá sản phẩm thành công' });
        handleDeleteRow(categoryId);
      })
      .catch((err) => {
        notificationController.error({
          message: t('common.error'),
          description: err.toString(),
        });
      });
  };

  const columns: ColumnsType<CategoryRow> = [
    {
      title: 'Tên',
      dataIndex: 'name',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Tên tiếng anh',
      dataIndex: 'name_eng',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active_status',
      render: (status: boolean, record: CategoryRow) => {
        return (
          <Popconfirm
            placement="topLeft"
            title={`Bạn có chắc chắn muốn ${status ? 'ẩn' : 'hiện'} danh mục này không?${
              status ? ' Các sản phẩm cũng sẽ được update theo!' : ''
            }`}
            onConfirm={() => handleUpdateStatus(record)}
            okText="Yes"
            cancelText="No"
          >
            <Switch checked={status} />
          </Popconfirm>
        );
      },
    },
    {
      title: 'Chức năng',
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record: CategoryRow) => {
        return (
          <BaseSpace>
            <BaseButton
              type="ghost"
              onClick={() => {
                setCategoryEdit(record);
              }}
            >
              Edit
            </BaseButton>
            <BasePopconfirm
              title={'Bạn chắc chắn muốn xoá danh mục này chứ? Các sản phẩm cũng sẽ được update theo!'}
              onConfirm={() => onDelete(record.key)}
            >
              <BaseButton type="default" danger>
                {t('tables.delete')}
              </BaseButton>
            </BasePopconfirm>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <>
      <DivStyled>
        <BaseButton
          type="link"
          onClick={() => {
            setOpenAdd(true);
          }}
        >
          Thêm danh mục
        </BaseButton>
      </DivStyled>
      <BaseTable
        columns={columns}
        dataSource={tableData.data}
        pagination={tableData.pagination}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      {categoryEdit && (
        <ModalCategoryEdit
          setIsRefetch={setIsRefetch}
          categoryEdit={categoryEdit}
          onClose={() => setCategoryEdit(null)}
        />
      )}
      {openAdd && <ModalCategoryEdit setIsRefetch={setIsRefetch} onClose={() => setOpenAdd(false)} />}
    </>
  );
};

export default CategoryTable;
