import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { useMounted } from '@app/hooks/useMounted';
import { getImagePath, getWebsitePath } from '@app/utils/common';
import { ColumnsType } from 'antd/es/table';
import { Pagination } from 'api/table.api';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { deleteNews, getNewsList, updateStatusNews } from '@app/api/news.api';
import { notificationController } from '@app/controllers/notificationController';
import { Popconfirm, Switch, Tag } from 'antd';
import { BasePopconfirm } from '../common/BasePopconfirm/BasePopconfirm';

const PAGE_DEFAULT = 1;
const PAGE_SIZE = 5;

const initialPagination: Pagination = {
  current: PAGE_DEFAULT,
  pageSize: PAGE_SIZE,
};

export interface NewsRow {
  key: number;
  news_title: string;
  news_title_eng: string;
  slug: string;
  slug_eng: string;
  img_url: string;
  active_status: boolean;
  description: string;
  description_eng: string;
  content: string;
  content_eng: string;
  keyword: string;
  product_related: string;
}

interface NewsTableProps {
  searchValue: string;
}

const NewsTable: React.FC<NewsTableProps> = ({ searchValue }) => {
  const [tableData, setTableData] = useState<{ data: NewsRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMounted } = useMounted();

  // Call api fetch category list
  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getNewsList({
        page: pagination.current || PAGE_DEFAULT,
        limit: pagination.pageSize || PAGE_SIZE,
        search: searchValue,
      }).then((res) => {
        if (isMounted.current) {
          setTableData({
            data: res.news.map((item) => ({
              key: item.id,
              news_title: item.news_title,
              news_title_eng: item.news_title_eng,
              slug: item.slug,
              slug_eng: item.slug_eng,
              content: item.content,
              content_eng: item.content_eng,
              active_status: !!item.active_status,
              img_url: item.img_url,
              description: item.description,
              description_eng: item.description_eng,
              keyword: item.keyword,
              product_related: item.product_related,
            })),
            pagination: {
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: res.total,
            },
            loading: false,
          });
        }
      });
    },
    [isMounted, searchValue],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.key !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
  };

  const onDelete = (newsId: number) => {
    deleteNews(newsId)
      .then(() => {
        notificationController.success({ message: 'Xoá tin tức thành công' });
        handleDeleteRow(newsId);
      })
      .catch((err) => {
        notificationController.error({
          message: t('common.error'),
          description: err.toString(),
        });
      });
  };

  const handleUpdateStatus = (record: NewsRow) => {
    updateStatusNews(record.key, !record.active_status ? 1 : 0)
      .then(() => {
        notificationController.success({
          message: 'Cập nhật tin tức thành công',
        });
        setTableData({
          ...tableData,
          data: tableData.data.map((item) =>
            item.key === record.key ? { ...item, active_status: !item.active_status } : item,
          ),
        });
      })
      .catch((err) => {
        notificationController.error({
          message: t('common.error'),
          description: err.toString(),
        });
      });
  };

  const columns: ColumnsType<NewsRow> = [
    {
      title: 'Ảnh',
      dataIndex: 'img_url',
      render: (text: string) => (
        <img src={getImagePath(text)} alt="img" width={320} height={200} style={{ objectFit: 'cover' }} />
      ),
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'news_title',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Tiêu đề tiếng anh',
      dataIndex: 'news_title_eng',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Keyword SEO',
      dataIndex: 'keyword',
      width: 200,
      render: (keyword: string) => {
        const arr = keyword.slice(1, -1).split(',');
        return (
          <>
            {arr.map((item, index) => (
              <Tag color="cyan" key={index} style={{ marginBottom: 4 }}>
                {item}
              </Tag>
            ))}
          </>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active_status',
      render: (status: boolean, record: NewsRow) => {
        return (
          <Popconfirm
            placement="topLeft"
            title={`Bạn có chắc chắn muốn ${status ? 'ẩn' : 'hiện'} tin tức này không?`}
            onConfirm={() => handleUpdateStatus(record)}
            okText="Yes"
            cancelText="No"
          >
            <Switch checked={status} />
          </Popconfirm>
        );
      },
    },
    {
      title: 'Chức năng',
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record) => {
        return (
          <BaseSpace>
            <BaseButton
              type="primary"
              onClick={() => {
                window.open(getWebsitePath(`/news/${record.slug}`), '_blank');
              }}
            >
              Link website
            </BaseButton>
            <BaseButton
              type="ghost"
              onClick={() => {
                navigate(`/news/edit?newsId=${record.key}`);
              }}
            >
              Edit
            </BaseButton>
            <BasePopconfirm title={'Bạn chắc chắn muốn xoá tin tức này chứ?'} onConfirm={() => onDelete(record.key)}>
              <BaseButton type="default" danger>
                {t('tables.delete')}
              </BaseButton>
            </BasePopconfirm>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <>
      <BaseTable
        columns={columns}
        dataSource={tableData.data}
        pagination={tableData.pagination}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
    </>
  );
};

export default NewsTable;
