import React, { useState } from 'react';
import { BaseModal } from '../common/BaseModal/BaseModal';
import { ProjectRow } from './ProjectTable';
import { ProductDetailHeader, ProductDetailStyled, ProductItemStyled } from '../product/ProductDetailStyled';
import { Switch } from 'antd';
import { useNavigate } from 'react-router-dom';
import { formatDate, getImagePath } from '@app/utils/common';

interface ModalProjectDetailProps {
  projectSelected: ProjectRow;
  onClose: VoidFunction;
}

enum Language {
  VN = 'VN',
  EN = 'EN',
}

const ModalProjectDetail = ({ projectSelected, onClose }: ModalProjectDetailProps) => {
  const [languageSelected, setLanguageSelected] = useState<Language>(Language.VN);
  const navigate = useNavigate();

  const imgArr = projectSelected.img_url.split(',');

  return (
    <BaseModal
      title={'Chi tiết dự án'}
      centered
      open
      onOk={() => navigate(`/projects/edit?projectId=${projectSelected.key}`)}
      onCancel={onClose}
      okText={'Edit'}
      size={'large'}
    >
      <ProductDetailHeader>
        <div className="change-language">
          <span>Xem bản: </span>
          <Switch
            checkedChildren={Language.VN}
            unCheckedChildren={'EN'}
            checked={languageSelected === Language.VN}
            onChange={(checked) => setLanguageSelected(checked ? Language.VN : Language.EN)}
          />
        </div>
      </ProductDetailHeader>
      <ProductDetailStyled>
        <div className="image">
          <img src={getImagePath(imgArr[0])} alt="" />
          <div className="image2">
            {imgArr[1] && <img src={getImagePath(imgArr[1])} alt="" />}
            {imgArr[2] && <img src={getImagePath(imgArr[2])} alt="" />}
          </div>
        </div>
        <div className="content">
          <div className="content--name">
            {languageSelected === Language.VN ? projectSelected.project_name : projectSelected.project_name_eng}
          </div>
          <div className="content--des">
            <ProductItemStyled>
              <div className="type">{languageSelected === Language.VN ? 'Khách hàng' : 'Client'}</div>
              <div className="value">
                {languageSelected === Language.VN ? projectSelected.client_name : projectSelected.client_name_eng}
              </div>
            </ProductItemStyled>
            <ProductItemStyled>
              <div className="type">{languageSelected === Language.VN ? 'Lĩnh vực' : 'Field'}</div>
              <div className="value">
                {languageSelected === Language.VN ? projectSelected.field : projectSelected.field_eng}
              </div>
            </ProductItemStyled>
            <ProductItemStyled>
              <div className="type">{languageSelected === Language.VN ? 'Ngày thực hiện' : 'Project date'}</div>
              <div className="value">{formatDate(projectSelected.project_date)}</div>
            </ProductItemStyled>
            <ProductItemStyled>
              <div className="type">{languageSelected === Language.VN ? 'Nội dung' : 'Description'}</div>
            </ProductItemStyled>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  languageSelected === Language.VN ? projectSelected.description : projectSelected.description_eng,
              }}
            />
          </div>
        </div>
      </ProductDetailStyled>
    </BaseModal>
  );
};

export default ModalProjectDetail;
