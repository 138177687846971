import { deleteProject, getProjectList } from '@app/api/project.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { useMounted } from '@app/hooks/useMounted';
import { formatDate, getImagePath } from '@app/utils/common';
import { ColumnsType } from 'antd/es/table';
import { Pagination } from 'api/table.api';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ModalProjectDetail from './ModalProjectDetail';
import { BasePopconfirm } from '../common/BasePopconfirm/BasePopconfirm';
import { notificationController } from '@app/controllers/notificationController';

const PAGE_DEFAULT = 1;
const PAGE_SIZE = 5;

const initialPagination: Pagination = {
  current: PAGE_DEFAULT,
  pageSize: PAGE_SIZE,
};

export interface ProjectRow {
  key: number;
  project_name: string;
  project_name_eng: string;
  client_name: string;
  client_name_eng: string;
  field: string;
  field_eng: string;
  description: string;
  description_eng: string;
  project_date: string;
  img_url: string;
}

interface ProjectTableProps {
  searchValue: string;
}

const ProjectTable: React.FC<ProjectTableProps> = ({ searchValue }) => {
  const [tableData, setTableData] = useState<{ data: ProjectRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMounted } = useMounted();
  const [projectSelected, setProjectSelected] = useState<ProjectRow | null>(null);

  // Call api fetch category list
  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getProjectList({
        page: pagination.current || PAGE_DEFAULT,
        limit: pagination.pageSize || PAGE_SIZE,
        search: searchValue,
      }).then((res) => {
        if (isMounted.current) {
          setTableData({
            data: res.projects.map((item) => ({
              key: item.id,
              project_name: item.project_name,
              project_name_eng: item.project_name_eng,
              client_name: item.client_name,
              client_name_eng: item.client_name_eng,
              field: item.field,
              field_eng: item.field_eng,
              description: item.description,
              description_eng: item.description_eng,
              project_date: item.project_date,
              img_url: item.img_url,
            })),
            pagination: {
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: res.total,
            },
            loading: false,
          });
        }
      });
    },
    [isMounted, searchValue],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.key !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
  };

  const onDelete = (projectId: number) => {
    deleteProject(projectId)
      .then(() => {
        notificationController.success({ message: 'Xoá dự án thành công' });
        handleDeleteRow(projectId);
      })
      .catch((err) => {
        notificationController.error({
          message: t('common.error'),
          description: err.toString(),
        });
      });
  };

  const columns: ColumnsType<ProjectRow> = [
    {
      title: 'Ảnh',
      dataIndex: 'img_url',
      render: (text: string) => (text ? <img src={getImagePath(text.split(',')[0])} alt="img" width={200} /> : null),
    },
    {
      title: 'Tên',
      dataIndex: 'project_name',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'client_name',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Lĩnh vực',
      dataIndex: 'field',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Ngày thực hiện',
      dataIndex: 'project_date',
      render: (text: string) => <span>{formatDate(text)}</span>,
    },
    {
      title: 'Chức năng',
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record) => {
        return (
          <BaseSpace>
            <BaseButton
              type="primary"
              onClick={() => {
                setProjectSelected(record);
              }}
            >
              View
            </BaseButton>
            <BaseButton
              type="ghost"
              onClick={() => {
                navigate(`/projects/edit?projectId=${record.key}`);
              }}
            >
              Edit
            </BaseButton>
            <BasePopconfirm title={'Bạn chắc chắn muốn xoá project này chứ?'} onConfirm={() => onDelete(record.key)}>
              <BaseButton type="default" danger>
                {t('tables.delete')}
              </BaseButton>
            </BasePopconfirm>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <>
      <BaseTable
        columns={columns}
        dataSource={tableData.data}
        pagination={tableData.pagination}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      {projectSelected && (
        <ModalProjectDetail projectSelected={projectSelected} onClose={() => setProjectSelected(null)} />
      )}
    </>
  );
};

export default ProjectTable;
