import { httpApi } from './http.api';

export const upload = (file: File) => {
  const formData = new FormData();
  formData.append('image', file);
  return httpApi.post('/upload', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};
