import { UserOutlined } from '@ant-design/icons';
import { IReview, ProductResponse, getProductDetailById } from '@app/api/product.api';
import { deleteReview } from '@app/api/review.api';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BasePopconfirm } from '@app/components/common/BasePopconfirm/BasePopconfirm';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import ModalAddReview from '@app/components/product/ModalAddReview';
import { notificationController } from '@app/controllers/notificationController';
import { formatDate, getImagePath } from '@app/utils/common';
import { Avatar, Button, Divider, List, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const ProductDetailStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: space-between;

  .product-info {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }
`;

const ProductReviewPage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [productDetail, setProductDetail] = useState<ProductResponse | null>(null);
  // const [productSelected, setProductSelected] = useState<ProductResponse | null>(null)
  const [isShowModalReview, setIsShowModalReview] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [reviewList, setReviewList] = useState<IReview[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    getProductDetailById(+id)
      .then((res) => {
        setProductDetail(res);
        setReviewList(res.reviews);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id, refetch]);

  const handleDeleteReview = (id: number) => {
    deleteReview(id)
      .then(() => {
        notificationController.success({ message: 'Xoá review thành công' });
        setRefetch((prev) => !prev);
      })
      .catch((err) => {
        notificationController.error({
          message: t('common.error'),
          description: err.toString(),
        });
      });
  };

  return (
    <>
      <PageTitle>Review sản phẩm</PageTitle>
      <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
        <BaseCol xs={24} xl={24}>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: '24px',
              fontWeight: 600,
            }}
          >
            Review sản phẩm
          </Typography>
        </BaseCol>
      </BaseRow>
      <ProductDetailStyled>
        <div className="product-info">
          <Avatar size={80} src={getImagePath(productDetail?.img_url || '')} />
          <span>{productDetail?.name}</span>
        </div>
        <Button
          type="primary"
          onClick={() => {
            if (reviewList.length >= 4) {
              message.info('Chỉ được thêm tối đa 4 review!');
              return;
            }
            setIsShowModalReview(true);
          }}
        >
          Thêm review
        </Button>
      </ProductDetailStyled>
      <Divider />
      <List
        loading={isLoading}
        itemLayout="horizontal"
        dataSource={reviewList}
        locale={{
          emptyText: 'Chưa có review nào về sản phẩm. Hãy thêm ngay!',
        }}
        renderItem={(item) => (
          <List.Item
            actions={[
              // <a key="list-loadmore-edit">edit</a>,
              <BasePopconfirm
                placement="topRight"
                key="list-loadmore-more"
                title={'Bạn chắc chắn muốn xoá review này chứ?'}
                onConfirm={() => handleDeleteReview(item.id)}
              >
                <a>delete</a>
              </BasePopconfirm>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={<span>{item.name}</span>}
              description={item.content}
            />
            <div>{formatDate(item.created_at)}</div>
          </List.Item>
        )}
      />
      {isShowModalReview && productDetail?.id && (
        <ModalAddReview
          productId={productDetail.id}
          onClose={() => {
            setIsShowModalReview(false);
          }}
          onDone={() => {
            setRefetch((prev) => !prev);
            setIsShowModalReview(false);
          }}
        />
      )}
    </>
  );
};

export default ProductReviewPage;
