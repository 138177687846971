import styled from 'styled-components';

export const ProductDetailHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  .change-language {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .ant-switch {
      width: fit-content;
    }
  }
`;

export const ProductDetailStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  img {
    width: 400px;
    height: 400px;
    object-fit: cover;
  }

  .image {
    display: flex;
    flex-direction: column;
    gap: 10px;

    img {
      width: 400px;
      height: 400px;
      object-fit: cover;
    }

    .image2 {
      display: flex;
      flex-direction: row;
      gap: 10px;

      img {
        width: 195px !important;
        height: 195px !important;
        object-fit: cover;
      }
    }
  }

  ul,
  ol {
    padding-left: 15px;
  }

  .content {
    flex: 1;
    &--name {
      font-size: 20px;
      margin-bottom: 12px;
    }

    &--des {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  }
`;

export const ProductItemStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .type {
    flex: 1;
  }

  .value {
    flex: 1;
    text-align: right;
    color: var(--text-light-color);
  }
`;

export const ProductDetailContentStyled = styled.div`
  img {
    width: 100%;
  }
`;
