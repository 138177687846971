import { ProductFormValues } from '@app/components/product/ProductForm';
import { httpApi } from './http.api';
import { convertFormData } from '@app/utils/common';
import { resizeImage } from '@app/utils/utils';

export interface IReview {
  id: number;
  avt: string;
  star: number;
  name: string;
  content: string;
  created_at: string;
}

export interface ProductResponse {
  id: number;
  name: string;
  name_eng: string;
  slug: string;
  slug_eng: string;
  img_url: string;
  product_code: string;
  id_category: string;
  active_status: string;
  size: string;
  size_eng: string;
  spec: string;
  spec_eng: string;
  area: string;
  quantitative: string;
  quantitative_eng: string;
  description: string;
  description_eng: string;
  reviews: IReview[];
}

interface IResponse {
  products: ProductResponse[];
  total: number;
}

interface IParam {
  search: string;
  page: number;
  limit: number;
}

export interface IProductRequest extends Omit<ProductFormValues, 'specs' | 'id_category'> {
  id_category: string;
  spec: string;
  spec_eng: string;
}

export const getProductList = (params: IParam): Promise<IResponse> => {
  return httpApi
    .get<IResponse>('/product/x', {
      params,
    })
    .then(({ data }) => data);
};

export const getProductActiveList = (): Promise<ProductResponse[]> => {
  return httpApi.get<ProductResponse[]>('/product/active').then(({ data }) => data);
};

export const addProduct = async (file: File, productRequest: IProductRequest): Promise<boolean> => {
  const formData = convertFormData(productRequest);
  const fileResize = await resizeImage(file);
  formData.append('file', fileResize);
  return httpApi.post('/product', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateProduct = async (
  productId: number,
  file: File | null,
  productRequest: IProductRequest,
  imgEdit: string,
): Promise<boolean> => {
  const formData = convertFormData(productRequest);
  imgEdit && formData.append('image_url', imgEdit);
  if (file) {
    const fileResize = await resizeImage(file);
    formData.append('file', fileResize);
  }
  return httpApi.put(`/product/${productId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateStatusProduct = (productId: number, status: number) => {
  return httpApi.put(`/product/status/${productId}`, { active_status: status });
};

export const getProductDetailById = (id: number): Promise<ProductResponse> => {
  return httpApi.get<ProductResponse>(`/product/${id}`).then(({ data }) => data);
};

export const deleteProduct = (productId: number) => {
  return httpApi.delete(`/product/${productId}`);
};
