import React, { useEffect, useState } from 'react';
import { BaseSelect, BaseSelectProps } from '../common/selects/BaseSelect/BaseSelect';
import { ProductResponse, getProductActiveList } from '@app/api/product.api';
import { BaseButtonsForm } from '../common/forms/BaseButtonsForm/BaseButtonsForm';

interface SelectProductProps extends BaseSelectProps {
  name: string;
  label?: string;
  required?: boolean;
}

const SelectProduct: React.FC<SelectProductProps> = ({ label, required, ...props }) => {
  const [products, setProducts] = useState<ProductResponse[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);
    getProductActiveList()
      .then((res) => {
        setProducts(res);
      })
      .catch((err) => {
        console.log('fetch products err', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <BaseButtonsForm.Item
      name="product_related"
      label={label}
      rules={[required ? { required: true, message: 'Vui lòng chọn các sản phẩm liên quan' } : {}]}
    >
      <BaseSelect
        mode="multiple"
        loading={loading}
        options={products.map((item) => ({
          label: item.name,
          value: item.id,
        }))}
        placeholder="Chọn sản phẩm"
        {...props}
      />
    </BaseButtonsForm.Item>
  );
};

export default SelectProduct;
