import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import ProductForm from '@app/components/product/ProductForm';

const ProductEditPage = () => {
  return (
    <>
      <PageTitle>Cập nhật sản phẩm</PageTitle>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24} md={24} xl={24}>
          <ProductForm />
        </BaseCol>
      </BaseRow>
    </>
  );
};

export default ProductEditPage;
