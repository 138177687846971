import { IForm } from '@app/components/category/ModalCategoryEdit';
import { httpApi } from './http.api';

interface CategoryResponse {
  id: number;
  name: string;
  name_eng: string;
  slug: string;
  slug_eng: string;
  active_status: number;
}

export const getCategoryList = (): Promise<CategoryResponse[]> => {
  return httpApi.get<CategoryResponse[]>('/category').then(({ data }) => data);
};

export const getCategoryListWithActive = (): Promise<CategoryResponse[]> => {
  return httpApi.get<CategoryResponse[]>('/category/active').then(({ data }) => data);
};

export const updateCategory = (categoryId: number, body: IForm & { active_status?: number }) => {
  return httpApi.put(`/category/${categoryId}`, body);
};

export const updateStatusCategory = (categoryId: number, status: number) => {
  return httpApi.put(`/category/status/${categoryId}`, { active_status: status });
};

export const addCategory = (body: IForm) => {
  return httpApi.post(`/category`, body);
};

export const deleteCategory = (categoryId: number) => {
  return httpApi.delete(`/category/x/${categoryId}`);
};
