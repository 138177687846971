import { CloseCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { addProject, getProjectDetailById, updateProject } from '@app/api/project.api';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { DayjsDatePicker } from '@app/components/common/pickers/DayjsDatePicker';
import { notificationController } from '@app/controllers/notificationController';
import { useQuery } from '@app/hooks/useQuery';
import { getImagePath, transformRequestProject } from '@app/utils/common';
import { Typography, Upload, message } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BaseButtonsGroup } from '../common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import FormEditor from '../editor/FormEditor';

const MultiUpload = styled(BaseCol)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  .ant-upload-picture-card-wrapper {
    width: auto;
  }
  .ant-upload-select-picture-card .ant-upload {
    position: relative;

    .anticon-close-circle {
      position: absolute;
      top: -8px;
      right: -8px;
    }
  }
`;

const ChooseDate = styled(BaseCol)`
  .ant-picker {
    width: 100%;
  }
`;

export interface ProjectFormValues {
  project_name: string;
  project_name_eng: string;
  client_name: string;
  client_name_eng: string;
  field: string;
  field_eng: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  project_date: any;
  description: string;
  description_eng: string;
}

const initialProjectFormValues: ProjectFormValues = {
  project_name: '',
  project_name_eng: '',
  client_name: '',
  client_name_eng: '',
  field: '',
  field_eng: '',
  project_date: '',
  description: '',
  description_eng: '',
};

const ProjectForm: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageFile1, setImageFile1] = useState<File | null>(null);
  const [imageFile2, setImageFile2] = useState<File | null>(null);
  const [imageFile3, setImageFile3] = useState<File | null>(null);
  const [imageEdit1, setImageEdit1] = useState('');
  const [imageEdit2, setImageEdit2] = useState('');
  const [imageEdit3, setImageEdit3] = useState('');
  const query = useQuery();
  const projectId = query.get('projectId');

  const [form] = BaseButtonsForm.useForm<ProjectFormValues>();

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!projectId) return;

    getProjectDetailById(Number(projectId))
      .then((res) => {
        const imgArr = res.img_url ? res.img_url.split(',') : '';
        setImageEdit1(imgArr ? imgArr[0] ?? '' : '');
        setImageEdit2(imgArr ? imgArr[1] ?? '' : '');
        setImageEdit3(imgArr ? imgArr[2] ?? '' : '');
        form.setFieldsValue({
          project_name: res.project_name,
          project_name_eng: res.project_name_eng,
          client_name: res.client_name,
          client_name_eng: res.client_name_eng,
          field: res.field,
          field_eng: res.field_eng,
          description: res.description,
          description_eng: res.description_eng,
          project_date: dayjs(res.project_date),
        });
        // setDescriptionVi(res.description);
        // setDescriptionEn(res.description_eng);
      })
      .catch((err) => {
        console.log('err', err);
        notificationController.error({
          message: t('common.error'),
          description: 'Đã có lỗi xảy ra',
        });
        setTimeout(() => {
          navigate(-1);
        }, 300);
      });
  }, [form, navigate, projectId, t]);

  const onFinish = useCallback(
    (values: ProjectFormValues) => {
      if (imageFile1 === null && imageEdit1 === '') {
        notificationController.error({
          message: t('common.error'),
          description: 'Vui lòng chọn ảnh 1 của dự án',
        });
        return;
      }

      // if (imageFile2 === null && imageEdit2 === '') {
      //   notificationController.error({
      //     message: t('common.error'),
      //     description: 'Vui lòng chọn ảnh 2 của dự án',
      //   });
      //   return;
      // }

      // if (imageFile3 === null && imageEdit3 === '') {
      //   notificationController.error({
      //     message: t('common.error'),
      //     description: 'Vui lòng chọn ảnh 3 của dự án',
      //   });
      //   return;
      // }

      // todo dispatch an action here
      setIsLoading(true);
      if (projectId) {
        updateProject(Number(projectId), imageFile1, imageFile2, imageFile3, imageEdit1, imageEdit2, imageEdit3, values)
          .then(() => {
            notificationController.success({ message: `Update dự án ${values.project_name} thành công` });
            setTimeout(() => {
              navigate(-1);
            }, 300);
          })
          .catch((err) => {
            notificationController.error({
              message: t('common.error'),
              description: err.toString(),
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        if (!imageFile1) return;
        addProject(imageFile1, imageFile2, imageFile3, transformRequestProject(values))
          .then(() => {
            notificationController.success({ message: 'Thêm dự án thành công' });
            setTimeout(() => {
              navigate(-1);
            }, 300);
          })
          .catch((err) => {
            notificationController.error({
              message: t('common.error'),
              description: err.toString(),
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    [imageFile1, imageEdit1, projectId, t, imageFile2, imageFile3, imageEdit2, imageEdit3, navigate],
  );

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleFileSelection = (file: File, index: number) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('Image must smaller than 10MB!');
      return;
    }
    setFieldsChanged(true);
    switch (index) {
      case 1:
        setImageFile1(file);
        break;
      case 2:
        setImageFile2(file);
        break;
      case 3:
        setImageFile3(file);
        break;
      default:
        break;
    }
  };

  const handleRemoveImage = (index: number, type: 'file' | 'previewFile') => {
    setFieldsChanged(true);
    switch (index) {
      // case 1:
      //   if (type === 'file') setImageFile1(null);
      //   if (type === 'previewFile') setImageEdit1('');
      //   break;
      case 2:
        if (type === 'file') setImageFile2(null);
        if (type === 'previewFile') setImageEdit2('');
        break;
      case 3:
        if (type === 'file') setImageFile3(null);
        if (type === 'previewFile') setImageEdit3('');
        break;
      default:
        break;
    }
  };

  return (
    <BaseCard>
      <BaseButtonsForm
        form={form}
        name="info"
        loading={isLoading}
        initialValues={initialProjectFormValues}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
        onFieldsChange={() => setFieldsChanged(true)}
        onFinish={onFinish}
        scrollToFirstError
        footer={
          projectId ? (
            <BaseButtonsGroup
              loading={isLoading}
              onCancel={() => navigate(-1)}
              textCancel="Trở về"
              textSubmit="Cập nhật"
            />
          ) : undefined
        }
      >
        <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
          <BaseCol xs={24} xl={24}>
            <Typography
              style={{
                textAlign: 'center',
                fontSize: '24px',
                fontWeight: 600,
              }}
            >
              {projectId ? 'Update dự án' : 'Tạo dự án'}
            </Typography>
          </BaseCol>
          <BaseCol span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>Thông tin dự án</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
          </BaseCol>

          <MultiUpload xs={24} md={12}>
            <Upload
              name="avatar1"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={(file) => {
                handleFileSelection(file, 1);
                return false;
              }}
            >
              {imageFile1 ? (
                <>
                  <img
                    src={URL.createObjectURL(imageFile1)}
                    alt="preview"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                  {/* <CloseCircleTwoTone
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleRemoveImage(1, 'file');
                    }}
                  /> */}
                </>
              ) : imageEdit1 ? (
                <>
                  <img
                    src={getImagePath(imageEdit1)}
                    alt="preview"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                  {/* <CloseCircleTwoTone
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleRemoveImage(1, 'previewFile');
                    }}
                  /> */}
                </>
              ) : (
                uploadButton
              )}
            </Upload>
            <Upload
              name="avatar2"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={(file) => {
                handleFileSelection(file, 2);
                return false;
              }}
            >
              {imageFile2 ? (
                <>
                  <img
                    src={URL.createObjectURL(imageFile2)}
                    alt="preview"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                  <CloseCircleTwoTone
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleRemoveImage(2, 'file');
                    }}
                  />
                </>
              ) : imageEdit2 ? (
                <>
                  <img
                    src={getImagePath(imageEdit2)}
                    alt="preview"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                  <CloseCircleTwoTone
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleRemoveImage(2, 'previewFile');
                    }}
                  />
                </>
              ) : (
                uploadButton
              )}
            </Upload>
            <Upload
              name="avatar3"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={(file) => {
                handleFileSelection(file, 3);
                return false;
              }}
            >
              {imageFile3 ? (
                <>
                  <img
                    src={URL.createObjectURL(imageFile3)}
                    alt="preview"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                  <CloseCircleTwoTone
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleRemoveImage(3, 'file');
                    }}
                  />
                </>
              ) : imageEdit3 ? (
                <>
                  <img
                    src={getImagePath(imageEdit3)}
                    alt="preview"
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                  <CloseCircleTwoTone
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleRemoveImage(3, 'previewFile');
                    }}
                  />
                </>
              ) : (
                uploadButton
              )}
            </Upload>
          </MultiUpload>

          <ChooseDate xs={24} md={12}>
            <BaseButtonsForm.Item
              name="project_date"
              label={'Ngày thực hiện'}
              rules={[{ required: true, message: 'Vui lòng chọn ngày thực hiện' }]}
            >
              <DayjsDatePicker placeholder="Chọn ngày thực hiện" format={'DD/MM/YYYY'} />
            </BaseButtonsForm.Item>
          </ChooseDate>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="project_name"
              label={'Tên dự án'}
              rules={[{ required: true, message: 'Vui lòng nhập tên dự án' }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="project_name_eng"
              label={'Tên dự án (EN)'}
              rules={[{ required: true, message: 'Vui lòng nhập tên dự án' }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="client_name"
              label={'Tên khách hàng'}
              rules={[{ required: true, message: 'Vui lòng nhập tên khách hàng' }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="client_name_eng"
              label={'Tên khách hàng (EN)'}
              rules={[{ required: true, message: 'Vui lòng nhập tên khách hàng' }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="field"
              label={'Lĩnh vực'}
              rules={[{ required: true, message: 'Vui lòng nhập lĩnh vực' }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="field_eng"
              label={'Lĩnh vực (EN)'}
              rules={[{ required: true, message: 'Vui lòng nhập lĩnh vực' }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <FormEditor name="description" label="Chi tiết" />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <FormEditor name="description_eng" label="Chi tiết (EN)" />
          </BaseCol>
        </BaseRow>
      </BaseButtonsForm>
    </BaseCard>
  );
};

export default ProjectForm;
