import { PlusOutlined } from '@ant-design/icons';
import { getCategoryListWithActive } from '@app/api/category.api';
import { addProduct, getProductDetailById, updateProduct } from '@app/api/product.api';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import * as S from '@app/components/forms/DynamicForm/DynamicForm.styles';
import { notificationController } from '@app/controllers/notificationController';
import { useQuery } from '@app/hooks/useQuery';
import { getImagePath, transformRequestProduct } from '@app/utils/common';
import { Typography, Upload, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BaseButton } from '../common/BaseButton/BaseButton';
import { BaseButtonsGroup } from '../common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import { BaseSelect, Option } from '../common/selects/BaseSelect/BaseSelect';
import FormEditor from '../editor/FormEditor';

export interface ProductFormValues {
  name: string;
  name_eng: string;
  slug: string;
  slug_eng: string;
  // img_url: string;
  product_code: string;
  id_category: number[];
  // active_status: boolean;
  size: string;
  size_eng: string;
  // spec: string;
  // spec_eng: string;
  area: string;
  quantitative: string;
  quantitative_eng: string;
  specs: {
    k1: string;
    v1: string;
    k2: string;
    v2: string;
  }[];
  description: string;
  description_eng: string;
}

const initialProductFormValues: ProductFormValues = {
  name: '',
  name_eng: '',
  slug: '',
  slug_eng: '',
  // img_url: '',
  product_code: '',
  id_category: [],
  // active_status: true,
  size: '',
  size_eng: '',
  // spec: '',
  // spec_eng: '',
  area: '',
  quantitative: '',
  quantitative_eng: '',
  specs: [],
  description: '',
  description_eng: '',
};

interface ICategory {
  id: number;
  name: string;
}

const ProductForm: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const [imageEdit, setImageEdit] = useState('');
  const query = useQuery();
  const productId = query.get('productId');

  const [form] = BaseButtonsForm.useForm<ProductFormValues>();

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    getCategoryListWithActive().then((res) => {
      setCategoryList(
        res.map((item) => ({
          id: item.id,
          name: item.name,
        })),
      );
    });
  }, []);

  useEffect(() => {
    if (!productId) return;

    getProductDetailById(Number(productId))
      .then((res) => {
        let specs: {
          k1: string;
          v1: string;
          k2: string;
          v2: string;
        }[] = [];
        if (Object.keys(res.spec).length === 0) {
          specs = [];
        } else {
          const arr1 = Object.entries(res.spec);
          const arr2 = Object.entries(res.spec_eng);

          arr1.forEach((item, index) => {
            specs.push({
              k1: arr1[index][0],
              v1: arr1[index][1],
              k2: arr2[index][0],
              v2: arr2[index][1],
            });
          });
        }
        setImageEdit(res.img_url);
        form.setFieldsValue({
          name: res.name,
          name_eng: res.name_eng,
          product_code: res.product_code,
          area: res.area,
          size: res.size,
          size_eng: res.size_eng,
          quantitative: res.quantitative,
          quantitative_eng: res.quantitative_eng,
          id_category: res.id_category
            .slice(1, -1)
            .split(',')
            .map((item) => Number(item)),
          specs,
          description: res.description,
          description_eng: res.description_eng,
        });
      })
      .catch((err) => {
        console.log('err', err);
        notificationController.error({
          message: t('common.error'),
          description: 'Đã có lỗi xảy ra',
        });
        setTimeout(() => {
          navigate(-1);
        }, 300);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, productId, t]);

  const onFinish = useCallback(
    (values: ProductFormValues) => {
      if (imageFile === null && imageEdit === '') {
        notificationController.error({
          message: t('common.error'),
          description: 'Vui lòng chọn ảnh sản phẩm',
        });
        return;
      }

      // todo dispatch an action here
      setIsLoading(true);
      if (productId) {
        updateProduct(Number(productId), imageFile, transformRequestProduct(values), imageEdit)
          .then(() => {
            notificationController.success({ message: `Update sản phẩm ${values.name} thành công` });
            setTimeout(() => {
              navigate(-1);
            }, 300);
          })
          .catch((err) => {
            notificationController.error({
              message: t('common.error'),
              description: err.toString(),
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        if (!imageFile) return;
        addProduct(imageFile, transformRequestProduct(values))
          .then(() => {
            notificationController.success({ message: 'Thêm sản phẩm thành công' });
            setTimeout(() => {
              navigate(-1);
            }, 300);
          })
          .catch((err) => {
            notificationController.error({
              message: t('common.error'),
              description: err.toString(),
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageFile, t, productId, imageEdit],
  );

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleFileSelection = (file: File) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('Image must smaller than 10MB!');
      return;
    }
    setFieldsChanged(true);
    setImageFile(file);
  };

  return (
    <BaseCard>
      <BaseButtonsForm
        form={form}
        name="info"
        loading={isLoading}
        initialValues={initialProductFormValues}
        isFieldsChanged={isFieldsChanged}
        setFieldsChanged={setFieldsChanged}
        onFieldsChange={() => setFieldsChanged(true)}
        onFinish={onFinish}
        scrollToFirstError
        footer={
          productId ? (
            <BaseButtonsGroup
              loading={isLoading}
              onCancel={() => navigate(-1)}
              textCancel="Trở về"
              textSubmit="Cập nhật"
            />
          ) : undefined
        }
      >
        <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
          <BaseCol xs={24} xl={24}>
            <Typography
              style={{
                textAlign: 'center',
                fontSize: '24px',
                fontWeight: 600,
              }}
            >
              {productId ? 'Update sản phẩm' : 'Tạo sản phẩm'}
            </Typography>
          </BaseCol>
          <BaseCol span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>Thông tin sản phẩm</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol span={24}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={(file) => {
                handleFileSelection(file);
                return false;
              }}
            >
              {imageFile ? (
                <img
                  src={URL.createObjectURL(imageFile)}
                  alt="preview"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              ) : imageEdit ? (
                <img
                  src={getImagePath(imageEdit)}
                  alt="preview"
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="name"
              label={'Tên sản phẩm'}
              rules={[{ required: true, message: 'Vui lòng nhập tên sản phẩm' }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="name_eng"
              label={'Tên sản phẩm (EN)'}
              rules={[{ required: true, message: 'Vui lòng nhập tên sản phẩm' }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="product_code"
              label={'Mã sản phẩm'}
              rules={[{ required: true, message: 'Vui lòng nhập mã sản phẩm' }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="id_category"
              label={'Thuộc danh mục'}
              rules={[{ required: true, message: 'Vui lòng chọn danh mục', type: 'array' }]}
            >
              <BaseSelect mode="multiple">
                {categoryList.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </BaseSelect>
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>{'Thông tin chi tiết'}</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="size"
              label={'Kích thước'}
              rules={[{ required: true, message: 'Vui lòng nhập kích thước' }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="size_eng"
              label={'Kích thước (EN)'}
              rules={[{ required: true, message: 'Vui lòng nhập kích thước' }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="quantitative"
              label={'Định lượng'}
              rules={[{ required: true, message: 'Vui lòng nhập định lượng' }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="quantitative_eng"
              label={'Định lượng (EN)'}
              rules={[{ required: true, message: 'Vui lòng nhập định lượng' }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <BaseButtonsForm.Item
              name="area"
              label={'Diện tích'}
              rules={[{ required: true, message: 'Vui lòng nhập diện tích' }]}
            >
              <BaseInput />
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>Đặc tính</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={24}>
            <BaseButtonsForm.List name="specs">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <BaseRow key={field.key} wrap={false} gutter={[10, 10]} align="middle" justify="space-between">
                      <BaseCol span={6}>
                        <BaseButtonsForm.Item
                          {...field}
                          name={[field.name, 'k1']}
                          fieldKey={[field.key, 'k1']}
                          label={'Thuộc tính'}
                          rules={[{ required: true, message: 'Vui lòng nhập thuộc tính' }]}
                        >
                          <BaseInput />
                        </BaseButtonsForm.Item>
                      </BaseCol>

                      <BaseCol span={6}>
                        <BaseButtonsForm.Item
                          {...field}
                          name={[field.name, 'v1']}
                          fieldKey={[field.key, 'v1']}
                          label={'Giá trị'}
                          rules={[{ required: true, message: 'Vui lòng nhập giá trị' }]}
                        >
                          <BaseInput />
                        </BaseButtonsForm.Item>
                      </BaseCol>

                      <BaseCol span={6}>
                        <BaseButtonsForm.Item
                          {...field}
                          name={[field.name, 'k2']}
                          fieldKey={[field.key, 'k2']}
                          label={'Thuộc tính (EN)'}
                          rules={[{ required: true, message: 'Vui lòng nhập thuộc tính' }]}
                        >
                          <BaseInput />
                        </BaseButtonsForm.Item>
                      </BaseCol>

                      <BaseCol span={6}>
                        <BaseButtonsForm.Item
                          {...field}
                          name={[field.name, 'v2']}
                          fieldKey={[field.key, 'v2']}
                          label={'Giá trị (EN)'}
                          rules={[{ required: true, message: 'Vui lòng nhập giá trị' }]}
                        >
                          <BaseInput />
                        </BaseButtonsForm.Item>
                        <S.RemoveBtn onClick={() => remove(field.name)} />
                      </BaseCol>
                    </BaseRow>
                  ))}

                  <BaseButtonsForm.Item>
                    <BaseButton type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Thêm đặc tính
                    </BaseButton>
                  </BaseButtonsForm.Item>
                </>
              )}
            </BaseButtonsForm.List>
          </BaseCol>

          <BaseCol span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>Thông tin thêm về sản phẩm</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <FormEditor name="description" label="Chi tiết" />
          </BaseCol>

          <BaseCol xs={24} md={12}>
            <FormEditor name="description_eng" label="Chi tiết (EN)" />
          </BaseCol>
        </BaseRow>
      </BaseButtonsForm>
    </BaseCard>
  );
};

export default ProductForm;
