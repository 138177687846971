import { BaseButton, BaseButtonProps } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import React from 'react';

interface BaseButtonsGroupProps extends BaseButtonProps {
  className?: string;
  onCancel: () => void;
  loading?: boolean;
  textCancel?: string;
  textSubmit?: string;
}

export const BaseButtonsGroup: React.FC<BaseButtonsGroupProps> = ({
  className,
  onCancel,
  loading,
  textCancel = 'Reset',
  textSubmit = 'Thêm',
  ...props
}) => {
  return (
    <BaseRow className={className} gutter={[10, 10]} wrap={false}>
      <BaseCol span={12}>
        <BaseButton block type="ghost" onClick={onCancel} {...props}>
          {textCancel}
        </BaseButton>
      </BaseCol>
      <BaseCol span={12}>
        <BaseButton block type="primary" loading={loading} htmlType="submit" {...props}>
          {textSubmit}
        </BaseButton>
      </BaseCol>
    </BaseRow>
  );
};
