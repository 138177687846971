import React, { useEffect, useMemo, useState } from 'react';
import { BaseModal } from '../common/BaseModal/BaseModal';
import {
  ProductDetailContentStyled,
  ProductDetailHeader,
  ProductDetailStyled,
  ProductItemStyled,
} from './ProductDetailStyled';
import { ProductRow } from './ProductTable';
import { getImagePath } from '@app/utils/common';
import { Switch } from 'antd';
import { getCategoryList } from '@app/api/category.api';
import { useNavigate } from 'react-router-dom';

interface ModalProductDetailProps {
  productSelected: ProductRow;
  onClose: VoidFunction;
}

enum Language {
  VN = 'VN',
  EN = 'EN',
}

interface ICategory {
  id: number;
  name: string;
  name_eng: string;
}

const ModalProductDetail = ({ productSelected, onClose }: ModalProductDetailProps) => {
  const [languageSelected, setLanguageSelected] = useState<Language>(Language.VN);
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCategoryList().then((res) => {
      setCategoryList(
        res.map((item) => ({
          id: item.id,
          name: item.name,
          name_eng: item.name_eng,
        })),
      );
    });
  }, []);

  const cateList = useMemo(() => {
    if (categoryList.length === 0) return '';

    const ids_category = productSelected.id_category
      .slice(1, -1)
      .split(',')
      .map((item) => Number(item));
    const vn = categoryList
      .filter((item) => ids_category.includes(item.id))
      .map((item) => item.name)
      .join(', ');
    const en = categoryList
      .filter((item) => ids_category.includes(item.id))
      .map((item) => item.name_eng)
      .join(', ');
    return [vn, en];
  }, [categoryList, productSelected.id_category]);

  return (
    <BaseModal
      title={'Chi tiết sản phẩm'}
      centered
      open
      onOk={() => navigate(`/products/edit?productId=${productSelected.key}`)}
      onCancel={onClose}
      okText={'Edit'}
      size={'large'}
    >
      <ProductDetailHeader>
        <div className="change-language">
          <span>Xem bản: </span>
          <Switch
            checkedChildren={Language.VN}
            unCheckedChildren={'EN'}
            checked={languageSelected === Language.VN}
            onChange={(checked) => setLanguageSelected(checked ? Language.VN : Language.EN)}
          />
        </div>
      </ProductDetailHeader>
      <ProductDetailStyled>
        <img src={getImagePath(productSelected.img_url)} alt="" />
        <div className="content">
          <div className="content--name">
            {languageSelected === Language.VN ? productSelected.name : productSelected.name_eng}
          </div>
          <div className="content--des">
            <ProductItemStyled>
              <div className="type">{languageSelected === Language.VN ? 'Mã sản phẩm' : 'Product code'}</div>
              <div className="value">{productSelected.product_code}</div>
            </ProductItemStyled>
            <ProductItemStyled>
              <div className="type">{languageSelected === Language.VN ? 'Kích thước' : 'Size'}</div>
              <div className="value">
                {languageSelected === Language.VN ? productSelected.size : productSelected.size_eng}
              </div>
            </ProductItemStyled>
            <ProductItemStyled>
              <div className="type">{languageSelected === Language.VN ? 'Diện tích' : 'Area'}</div>
              <div className="value">{productSelected.area}</div>
            </ProductItemStyled>
            <ProductItemStyled>
              <div className="type">{languageSelected === Language.VN ? 'Định lượng' : 'Quantiative'}</div>
              <div className="value">
                {languageSelected === Language.VN ? productSelected.quantitative : productSelected.quantitative_eng}
              </div>
            </ProductItemStyled>
            <ProductItemStyled>
              <div className="type">{languageSelected === Language.VN ? 'Danh mục' : 'Category'}</div>
              <div className="value">{languageSelected === Language.VN ? cateList[0] : cateList[1]}</div>
            </ProductItemStyled>
            {Object.keys(productSelected.spec).length !== 0 ? (
              <ProductItemStyled>
                <div className="type">{languageSelected === Language.VN ? 'Đặc tính' : 'Attribute'}</div>
              </ProductItemStyled>
            ) : null}
            {languageSelected === Language.VN
              ? Object.entries(productSelected.spec).map(([key, value], index) => {
                  return (
                    <ProductItemStyled key={index}>
                      <div className="type">{key}</div>
                      <div className="value">{value as string}</div>
                    </ProductItemStyled>
                  );
                })
              : Object.entries(productSelected.spec_eng).map(([key, value], index) => {
                  return (
                    <ProductItemStyled key={index}>
                      <div className="type">{key}</div>
                      <div className="value">{value as string}</div>
                    </ProductItemStyled>
                  );
                })}
          </div>
        </div>
      </ProductDetailStyled>
      <ProductDetailContentStyled>
        <div
          dangerouslySetInnerHTML={{
            __html: languageSelected === Language.VN ? productSelected.description : productSelected.description_eng,
          }}
        ></div>
      </ProductDetailContentStyled>
    </BaseModal>
  );
};

export default ModalProductDetail;
