import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BaseModal } from '../common/BaseModal/BaseModal';
import { CategoryRow } from './CategoryTable';
import { Form } from 'antd';
import { BaseCol } from '../common/BaseCol/BaseCol';
import { BaseButtonsForm } from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import { BaseRow } from '../common/BaseRow/BaseRow';
import styled from 'styled-components';
import { addCategory, updateCategory } from '@app/api/category.api';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';

interface ModalCategoryEditProps {
  onClose: VoidFunction;
  categoryEdit?: CategoryRow;
  setIsRefetch: Dispatch<SetStateAction<boolean>>;
}

export interface IForm {
  name: string;
  name_eng: string;
}

const ModalStyled = styled.div`
  .ant-form-item-label {
    min-width: 165px;
  }
`;

const ModalCategoryEdit = ({ onClose, categoryEdit, setIsRefetch }: ModalCategoryEditProps) => {
  const [form] = Form.useForm<IForm>();
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (categoryEdit) {
      form.setFieldsValue({
        name: categoryEdit.name,
        name_eng: categoryEdit.name_eng,
      });
    }
  }, [categoryEdit, form]);

  const handleSubmit = (values: IForm) => {
    setSubmitting(true);
    if (categoryEdit) {
      updateCategory(categoryEdit.key, { ...values, active_status: categoryEdit.active_status ? 1 : 0 })
        .then(() => {
          notificationController.success({
            message: 'Cập nhật danh mục thành công',
          });
          onClose();
          setIsRefetch((prev) => !prev);
        })
        .catch((err) => {
          notificationController.error({
            message: t('common.error'),
            description: err.toString(),
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      addCategory(values)
        .then(() => {
          notificationController.success({
            message: 'Thêm danh mục thành công',
          });
          onClose();
          setIsRefetch((prev) => !prev);
        })
        .catch((err) => {
          notificationController.error({
            message: t('common.error'),
            description: err.toString(),
          });
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <BaseModal
        title={!!categoryEdit ? 'Update danh mục' : 'Thêm danh mục'}
        centered
        open
        onOk={() => form.submit()}
        onCancel={onClose}
        okText={!!categoryEdit ? 'Cập nhật' : 'Thêm mới'}
        size={'large'}
        className="category-modal"
        confirmLoading={isSubmitting}
      >
        <ModalStyled>
          <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
            <BaseCol span={24}>
              <BaseButtonsForm.Item
                name="name"
                label={'Tên danh mục'}
                rules={[{ required: true, message: 'Vui lòng nhập tên danh mục' }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
            <BaseCol span={24}>
              <BaseButtonsForm.Item
                name="name_eng"
                label={'Tên danh mục (EN)'}
                rules={[{ required: true, message: 'Vui lòng nhập tên danh mục' }]}
              >
                <BaseInput />
              </BaseButtonsForm.Item>
            </BaseCol>
          </BaseRow>
        </ModalStyled>
      </BaseModal>
    </Form>
  );
};

export default ModalCategoryEdit;
