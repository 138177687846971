/* eslint-disable @typescript-eslint/no-explicit-any */
import { INewsRequest } from '@app/api/news.api';
import { IProductRequest } from '@app/api/product.api';
import { NewsFormValues } from '@app/components/news/NewsForm';
import { ProductFormValues } from '@app/components/product/ProductForm';
import { ProjectFormValues } from '@app/components/project/ProjectForm';
import dayjs from 'dayjs';

export const getImagePath = (fileName: string) => `${process.env.REACT_APP_BASE_URL}/images/${fileName}`;
export const getWebsitePath = (path: string) => `${process.env.REACT_APP_WEBSITE_URL}${path}`;

export const transformRequestProduct = (formValues: ProductFormValues): IProductRequest => {
  const { specs, id_category, ...rest } = formValues;

  const spec: any = {};
  const spec_eng: any = {};

  specs.forEach((item) => {
    spec[item.k1] = item.v1;
    spec_eng[item.k2] = item.v2;
  });

  return {
    ...rest,
    id_category: id_category.join(','),
    spec: JSON.stringify(spec),
    spec_eng: JSON.stringify(spec_eng),
  };
};

export const transformRequestProject = (formValues: ProjectFormValues): ProjectFormValues => {
  return {
    ...formValues,
    project_date: dayjs(formValues.project_date).toISOString(),
  };
};

export const transformRequestNews = (formValues: NewsFormValues): INewsRequest => {
  return {
    ...formValues,
    keyword: formValues.keyword.join(','),
    product_related: formValues.product_related.join(','),
  };
};

export const convertFormData = (formValues: any) => {
  const formData = new FormData();
  Object.keys(formValues).forEach((key) => formData.append(key, formValues[key]));
  return formData;
};

export const removeAccents = (str: string) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D');
};

export const formatDate = (date: string) => (date ? dayjs(date).format('DD/MM/YYYY') : '-');
