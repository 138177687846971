import CategoryTable from '@app/components/category/CategoryTable';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import React from 'react';

const CategoryPage = () => {
  return (
    <>
      <PageTitle>Danh mục</PageTitle>
      <BaseCard>
        <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
          <BaseCol span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>Danh sách danh mục</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
            <CategoryTable />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </>
  );
};

export default CategoryPage;
