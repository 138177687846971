import { createAction, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { UserModel } from '@app/domain/UserModel';
import { persistUser, readUser } from '@app/services/localStorage.service';
import { CompanyModel } from '@app/domain/CompanyModal';

export interface UserState {
  user: UserModel | null;
  company: CompanyModel | null;
}

const initialState: UserState = {
  user: readUser(),
  company: null,
};

export const setUser = createAction<
  PrepareAction<{
    user: UserModel;
    company: CompanyModel;
  }>
>('user/setUser', (newUser, companyInfo) => {
  persistUser(newUser);
  // persistCompany(companyInfo);

  return {
    payload: {
      user: newUser,
      company: companyInfo,
    },
  };
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload.user;
      state.company = action.payload.company;
    });
  },
});

export default userSlice.reducer;
