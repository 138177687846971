import { deleteProduct, getProductList, updateStatusProduct } from '@app/api/product.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { useMounted } from '@app/hooks/useMounted';
import { getImagePath } from '@app/utils/common';
import { ColumnsType } from 'antd/es/table';
import { Pagination } from 'api/table.api';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ModalProductDetail from './ModalProductDetail';
import { BasePopconfirm } from '../common/BasePopconfirm/BasePopconfirm';
import { notificationController } from '@app/controllers/notificationController';
import { Popconfirm, Switch, Tag } from 'antd';
import { getCategoryList } from '@app/api/category.api';

const PAGE_DEFAULT = 1;
const PAGE_SIZE = 5;

const initialPagination: Pagination = {
  current: PAGE_DEFAULT,
  pageSize: PAGE_SIZE,
};

export interface ProductRow {
  key: number;
  name: string;
  name_eng: string;
  slug: string;
  slug_eng: string;
  img_url: string;
  product_code: string;
  id_category: string;
  active_status: boolean;
  size: string;
  size_eng: string;
  spec: string;
  spec_eng: string;
  area: string;
  quantitative: string;
  quantitative_eng: string;
  description: string;
  description_eng: string;
}

interface ProductTableProps {
  searchValue: string;
}

interface ICategory {
  id: number;
  name: string;
}

const ProductTable: React.FC<ProductTableProps> = ({ searchValue }) => {
  const [tableData, setTableData] = useState<{ data: ProductRow[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [categoryList, setCategoryList] = useState<ICategory[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMounted } = useMounted();
  const [productSelected, setProductSelected] = useState<ProductRow | null>(null);

  // Call api fetch category list
  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      getProductList({
        page: pagination.current || PAGE_DEFAULT,
        limit: pagination.pageSize || PAGE_SIZE,
        search: searchValue,
      }).then((res) => {
        if (isMounted.current) {
          setTableData({
            data: res.products.map((item) => ({
              key: item.id,
              name: item.name,
              name_eng: item.name_eng,
              slug: item.slug,
              slug_eng: item.slug_eng,
              active_status: !!item.active_status,
              img_url: item.img_url,
              product_code: item.product_code,
              id_category: item.id_category,
              size: item.size,
              size_eng: item.size_eng,
              spec: item.spec,
              spec_eng: item.spec_eng,
              area: item.area,
              quantitative: item.quantitative,
              quantitative_eng: item.quantitative_eng,
              description: item.description,
              description_eng: item.description_eng,
            })),
            pagination: {
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: res.total,
            },
            loading: false,
          });
        }
      });
    },
    [isMounted, searchValue],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch]);

  useEffect(() => {
    getCategoryList().then((res) => {
      setCategoryList(
        res.map((item) => ({
          id: item.id,
          name: item.name,
        })),
      );
    });
  }, []);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };

  const handleDeleteRow = (rowId: number) => {
    setTableData({
      ...tableData,
      data: tableData.data.filter((item) => item.key !== rowId),
      pagination: {
        ...tableData.pagination,
        total: tableData.pagination.total ? tableData.pagination.total - 1 : tableData.pagination.total,
      },
    });
  };

  const onDelete = (productId: number) => {
    deleteProduct(productId)
      .then(() => {
        notificationController.success({ message: 'Xoá sản phẩm thành công' });
        handleDeleteRow(productId);
      })
      .catch((err) => {
        notificationController.error({
          message: t('common.error'),
          description: err.toString(),
        });
      });
  };

  const handleUpdateStatus = (record: ProductRow) => {
    updateStatusProduct(record.key, !record.active_status ? 1 : 0)
      .then(() => {
        notificationController.success({
          message: 'Cập nhật sản phẩm thành công',
        });
        setTableData({
          ...tableData,
          data: tableData.data.map((item) =>
            item.key === record.key ? { ...item, active_status: !item.active_status } : item,
          ),
        });
      })
      .catch((err) => {
        notificationController.error({
          message: t('common.error'),
          description: err.toString(),
        });
      });
  };

  const columns: ColumnsType<ProductRow> = [
    {
      title: 'Mã',
      dataIndex: 'product_code',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Ảnh',
      dataIndex: 'img_url',
      render: (text: string) => <img src={getImagePath(text)} alt="img" width={200} />,
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Tên tiếng anh',
      dataIndex: 'name_eng',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Danh mục',
      dataIndex: 'id_category',
      render: (text: string) => {
        const ids_category = text;
        const arr = ids_category.slice(1, -1).split(',');
        const cateList = categoryList.filter((item) => arr.includes(`${item.id}`)).map((item) => item.name);
        return (
          <>
            {cateList.map((item, index) => (
              <Tag color="cyan" key={index}>
                {item}
              </Tag>
            ))}
          </>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active_status',
      render: (status: boolean, record: ProductRow) => {
        return (
          <Popconfirm
            placement="topLeft"
            title={`Bạn có chắc chắn muốn ${status ? 'ẩn' : 'hiện'} sản phẩm này không?`}
            onConfirm={() => handleUpdateStatus(record)}
            okText="Yes"
            cancelText="No"
          >
            <Switch checked={status} />
          </Popconfirm>
        );
      },
    },
    {
      title: 'Chức năng',
      dataIndex: 'actions',
      width: '15%',
      render: (text: string, record) => {
        return (
          <BaseSpace>
            <BaseButton
              type="primary"
              onClick={() => {
                setProductSelected(record);
              }}
            >
              View
            </BaseButton>
            <BaseButton
              type="default"
              onClick={() => {
                navigate(`/products/${record.key}`);
              }}
            >
              Review
            </BaseButton>
            <BaseButton
              type="ghost"
              onClick={() => {
                navigate(`/products/edit?productId=${record.key}`);
              }}
            >
              Edit
            </BaseButton>
            <BasePopconfirm title={'Bạn chắc chắn muốn xoá product này chứ?'} onConfirm={() => onDelete(record.key)}>
              <BaseButton type="default" danger>
                {t('tables.delete')}
              </BaseButton>
            </BasePopconfirm>
          </BaseSpace>
        );
      },
    },
  ];

  return (
    <>
      <BaseTable
        columns={columns}
        dataSource={tableData.data}
        pagination={tableData.pagination}
        loading={tableData.loading}
        onChange={handleTableChange}
        scroll={{ x: 800 }}
        bordered
      />
      {productSelected && (
        <ModalProductDetail productSelected={productSelected} onClose={() => setProductSelected(null)} />
      )}
    </>
  );
};

export default ProductTable;
