/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlusOutlined } from '@ant-design/icons';
import { addNews, getNewsDetailById, updateNews } from '@app/api/news.api';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { notificationController } from '@app/controllers/notificationController';
import { useQuery } from '@app/hooks/useQuery';
import { getImagePath, transformRequestNews } from '@app/utils/common';
import { Spin, Typography, Upload, message } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BaseButtonsGroup } from '../common/forms/components/BaseButtonsGroup/BaseButtonsGroup';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import { BaseSelect } from '../common/selects/BaseSelect/BaseSelect';
import FormEditor from '../editor/FormEditor';
import SelectProduct from './SelectProduct';

const WrapperUpload = styled(BaseCol)`
  display: flex;
  flex-direction: row;
  gap: 12px;
  .ant-upload-picture-card-wrapper {
    width: auto;
  }
  .ant-upload-select-picture-card {
    width: 320px;
    height: 200px;
    .ant-upload {
      position: relative;

      .anticon-close-circle {
        position: absolute;
        top: -8px;
        right: -8px;
      }
    }
  }
`;

export interface NewsFormValues {
  news_title: string;
  news_title_eng: string;
  description: string;
  description_eng: string;
  content: string;
  content_eng: string;

  keyword: string[];
  product_related: number[];
}

const initialNewsFormValues: NewsFormValues = {
  news_title: '',
  news_title_eng: '',
  description: '',
  description_eng: '',
  content: '',
  content_eng: '',
  keyword: [],
  product_related: [],
};

const NewsForm: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageFile1, setImageFile1] = useState<File | null>(null);
  const [imageEdit1, setImageEdit1] = useState('');
  const query = useQuery();
  const newsId = query.get('newsId');

  const [form] = BaseButtonsForm.useForm<NewsFormValues>();
  const formValues = useWatch([], form);

  console.log(formValues);

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!newsId) return;
    setIsLoading(true);
    getNewsDetailById(Number(newsId))
      .then((res) => {
        setImageEdit1(res.img_url);
        form.setFieldsValue({
          news_title: res.news_title,
          news_title_eng: res.news_title_eng,
          description: res.description,
          description_eng: res.description_eng,
          content: res.content,
          content_eng: res.content_eng,
          keyword: res.keyword.slice(1, -1).split(','),
          product_related: res.product_related
            .slice(1, -1)
            .split(',')
            .map((item) => Number(item)),
        });
      })
      .catch((err) => {
        console.log('err', err);
        notificationController.error({
          message: t('common.error'),
          description: 'Đã có lỗi xảy ra',
        });
        setTimeout(() => {
          navigate(-1);
        }, 300);
      })
      .finally(() => setIsLoading(false));
  }, [form, navigate, newsId, t]);

  const onFinish = useCallback(
    (values: NewsFormValues) => {
      if (imageFile1 === null && imageEdit1 === '') {
        notificationController.error({
          message: t('common.error'),
          description: 'Vui lòng chọn ảnh chính của tin tức',
        });
        return;
      }

      // todo dispatch an action here
      setIsLoading(true);
      if (newsId) {
        updateNews(Number(newsId), imageFile1, transformRequestNews(values), imageEdit1)
          .then(() => {
            notificationController.success({ message: `Update tin tức ${values.news_title} thành công` });
            setTimeout(() => {
              navigate(-1);
            }, 300);
          })
          .catch((err) => {
            notificationController.error({
              message: t('common.error'),
              description: err.toString(),
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        if (!imageFile1) return;
        addNews(imageFile1, transformRequestNews(values))
          .then(() => {
            notificationController.success({ message: 'Thêm tin tức thành công' });
            setTimeout(() => {
              navigate(-1);
            }, 300);
          })
          .catch((err) => {
            notificationController.error({
              message: t('common.error'),
              description: err.toString(),
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageFile1, imageEdit1, newsId, t],
  );

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleFileSelection = (file: File, index: number) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('Image must smaller than 10MB!');
      return;
    }
    setFieldsChanged(true);
    switch (index) {
      case 1:
        setImageFile1(file);
        break;
      default:
        break;
    }
  };

  return (
    <Spin spinning={isLoading}>
      <BaseCard>
        <BaseButtonsForm
          form={form}
          name="info"
          loading={isLoading}
          initialValues={initialNewsFormValues}
          isFieldsChanged={isFieldsChanged}
          setFieldsChanged={setFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          onFinish={onFinish}
          scrollToFirstError
          footer={
            newsId ? (
              <BaseButtonsGroup
                loading={isLoading}
                onCancel={() => navigate(-1)}
                textCancel="Trở về"
                textSubmit="Cập nhật"
              />
            ) : undefined
          }
        >
          <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
            <BaseCol xs={24} xl={24}>
              <Typography
                style={{
                  textAlign: 'center',
                  fontSize: '24px',
                  fontWeight: 600,
                }}
              >
                {newsId ? 'Update tin tức' : 'Tạo tin tức'}
              </Typography>
            </BaseCol>
            <BaseCol span={24}>
              <BaseButtonsForm.Item>
                <BaseButtonsForm.Title>Thông tin tin tức</BaseButtonsForm.Title>
              </BaseButtonsForm.Item>
            </BaseCol>

            <WrapperUpload xs={24} md={12}>
              <Upload
                name="avatar1"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={(file) => {
                  handleFileSelection(file, 1);
                  return false;
                }}
                style={{ width: 200 }}
              >
                {imageFile1 ? (
                  <>
                    <img
                      src={URL.createObjectURL(imageFile1)}
                      alt="preview"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </>
                ) : imageEdit1 ? (
                  <>
                    <img
                      src={getImagePath(imageEdit1)}
                      alt="preview"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </>
                ) : (
                  uploadButton
                )}
              </Upload>
            </WrapperUpload>
            <BaseCol xs={24} md={12}></BaseCol>

            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item
                name="news_title"
                label={'Tiêu đề tin tức'}
                rules={[{ required: true, message: 'Vui lòng nhập tiêu đề tin tức' }]}
              >
                <BaseInput.TextArea placeholder="Nhập tiêu đề" />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item
                name="news_title_eng"
                label={'Tiêu đề tin tức (EN)'}
                rules={[{ required: true, message: 'Vui lòng nhập tiêu đề tin tức' }]}
              >
                <BaseInput.TextArea placeholder="Nhập tiêu đề" />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item
                name="description"
                label={'Mô tả ngắn'}
                rules={[{ required: true, message: 'Vui lòng nhập mô tả ngắn' }]}
              >
                <BaseInput.TextArea placeholder="Nhập mô tả ngắn" />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item
                name="description_eng"
                label={'Mô tả ngắn (EN)'}
                rules={[{ required: true, message: 'Vui lòng nhập mô tả ngắn' }]}
              >
                <BaseInput.TextArea placeholder="Nhập mô tả ngắn" />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={12}>
              <BaseButtonsForm.Item
                name="keyword"
                label={'Từ khóa SEO'}
                rules={[{ required: true, message: 'Vui lòng nhập mô tả ngắn' }]}
              >
                <BaseSelect
                  tokenSeparators={[',']}
                  mode="tags"
                  options={[
                    {
                      label: 'VDA VINA',
                      value: 'VDA VINA',
                    },
                  ]}
                  placeholder="Chọn hoặc nhập từ khóa"
                />
              </BaseButtonsForm.Item>
            </BaseCol>

            <BaseCol xs={24} md={12}>
              <SelectProduct name="product_related" label="Chọn sản phẩm liên quan" required />
            </BaseCol>

            <BaseCol xs={24} md={24}>
              <FormEditor name="content" label="Chi tiết tin tức" />
            </BaseCol>

            <BaseCol xs={24} md={24}>
              <FormEditor name="content_eng" label="Chi tiết tin tức (EN)" />
            </BaseCol>
          </BaseRow>
        </BaseButtonsForm>
      </BaseCard>
    </Spin>
  );
};

export default NewsForm;
