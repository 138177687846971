import LoginPage from '@app/pages/LoginPage';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import CategoryPage from '@app/pages/CategoryPage';
import ProductAddPage from '@app/pages/ProductAddPage';
import ProductEditPage from '@app/pages/ProductEditPage';
import ProductListPage from '@app/pages/ProductListPage';
import ProjectAddPage from '@app/pages/ProjectAddPage';
import ProjectEditPage from '@app/pages/ProjectEditPage';
import ProjectListPage from '@app/pages/ProjectListPage';
import ProductReviewPage from '@app/pages/ProductReviewPage';
import NewsListPage from '@app/pages/NewsListPage';
import NewsAddPage from '@app/pages/NewsAddPage';
import NewsEditPage from '@app/pages/NewsEditPage';
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const InfoPage = React.lazy(() => import('@app/pages/InfoPage'));
const Logout = React.lazy(() => import('./Logout'));

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';
const Error404 = withLoading(Error404Page);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

// Added
const Info = withLoading(InfoPage);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={NFT_DASHBOARD_PATH} element={protectedLayout}>
          <Route path="404" element={<Error404 />} />
          {/* added */}
          <Route index element={<Info />} />
          <Route path="/products/category" element={<CategoryPage />} />
          <Route path="/products/list" element={<ProductListPage />} />
          <Route path="products/:id" element={<ProductReviewPage />} />
          <Route path="/products/add" element={<ProductAddPage />} />
          <Route path="/products/edit" element={<ProductEditPage />} />
          <Route path="/projects/list" element={<ProjectListPage />} />
          <Route path="/projects/add" element={<ProjectAddPage />} />
          <Route path="/projects/edit" element={<ProjectEditPage />} />

          <Route path="/news/list" element={<NewsListPage />} />
          <Route path="/news/add" element={<NewsAddPage />} />
          <Route path="/news/edit" element={<NewsEditPage />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
