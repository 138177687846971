import React, { useState } from 'react';
import { BaseModal } from '../common/BaseModal/BaseModal';
import { DatePicker, Rate } from 'antd';
import styled from 'styled-components';
import { BaseButtonsForm } from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import { addReview } from '@app/api/review.api';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';

const ModalAddReviewStyled = styled.div`
  .ant-rate {
    font-size: 20px;
  }
`;

export interface ReviewFormValues {
  star: number;
  review_date: string;
  name: string;
  content: string;
}

interface ModalAddReviewProps {
  productId: number;
  onClose: VoidFunction;
  onDone: VoidFunction;
}

const ModalAddReview = ({ productId, onClose, onDone }: ModalAddReviewProps) => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [form] = BaseButtonsForm.useForm<ReviewFormValues>();
  const { t } = useTranslation();

  const onFinish = (values: ReviewFormValues) => {
    addReview(productId, values)
      .then(() => {
        notificationController.success({ message: 'Thêm review thành công' });
        onDone();
      })
      .catch((err) => {
        notificationController.error({
          message: t('common.error'),
          description: err.toString(),
        });
        onClose();
      });
  };

  return (
    <BaseModal onCancel={onClose} title={'Thêm review'} centered open okText={'Edit'} size={'large'} footer={null}>
      <ModalAddReviewStyled>
        <BaseButtonsForm
          form={form}
          name="add_review"
          isFieldsChanged={isFieldsChanged}
          setFieldsChanged={setFieldsChanged}
          onFieldsChange={() => setFieldsChanged(true)}
          onFinish={onFinish}
          initialValues={{
            star: 4,
          }}
        >
          <BaseButtonsForm.Item
            name="star"
            label="Rate"
            rules={[{ required: true, message: 'Vui lòng chọn đánh giá' }]}
          >
            <Rate />
          </BaseButtonsForm.Item>
          <BaseButtonsForm.Item
            name="review_date"
            label="Chọn ngày review"
            rules={[{ required: true, message: 'Vui lòng chọn ngày đánh giá' }]}
          >
            <DatePicker />
          </BaseButtonsForm.Item>
          <BaseButtonsForm.Item
            name="name"
            label={'Tên'}
            rules={[{ required: true, message: 'Vui lòng nhập tên người review' }]}
          >
            <BaseInput />
          </BaseButtonsForm.Item>
          <BaseButtonsForm.Item
            name="content"
            label={'Nội dung'}
            rules={[{ required: true, message: 'Vui lòng nhập nội dung review' }]}
          >
            <BaseInput.TextArea />
          </BaseButtonsForm.Item>
        </BaseButtonsForm>
      </ModalAddReviewStyled>
    </BaseModal>
  );
};

export default ModalAddReview;
