/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { BaseButtonsForm } from '../common/forms/BaseButtonsForm/BaseButtonsForm';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { resizeImage } from '@app/utils/utils';
import { upload } from '@app/api/common.api';
import { getImagePath } from '@app/utils/common';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface FormEditorProps {
  name: string;
  label: string;
}

const FormEditor = ({ name, label }: FormEditorProps) => {
  function uploadAdapter(loader: any) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then(async (file: File) => {
            const fileUpload = await resizeImage(file);
            upload(fileUpload as File)
              .then((res) => {
                resolve({
                  default: getImagePath(res.data),
                });
              })
              .catch((err) => {
                reject(err.message);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <BaseButtonsForm.Item
      name={name}
      label={label}
      rules={[{ required: true, message: `Vui lòng nhập ${label.toLowerCase()}` }]}
      valuePropName="data"
      getValueFromEvent={(event, editor) => {
        const data = editor.getData();
        return data;
      }}
    >
      <CKEditor
        editor={ClassicEditor}
        config={{
          extraPlugins: [uploadPlugin],
          mediaEmbed: {
            previewsInData: true,
          },
        }}
      />
    </BaseButtonsForm.Item>
  );
};

export default FormEditor;
