import { ReviewFormValues } from '@app/components/product/ModalAddReview';
import { httpApi } from './http.api';

export const addReview = (productId: number, reviewRequest: ReviewFormValues): Promise<boolean> => {
  return httpApi.post('/review', {
    ...reviewRequest,
    product_id: productId,
  });
};

export const deleteReview = (reviewId: number): Promise<boolean> => {
  return httpApi.delete(`/review/${reviewId}`);
};
