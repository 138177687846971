import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { SearchInput } from '@app/components/common/inputs/SearchInput/SearchInput';
import ProjectTable from '@app/components/project/ProjectTable';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const DivStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 20px;
  align-items: center;

  a {
    display: inline-block;
    flex-shrink: 0;
  }
`;

const ProjectListPage = () => {
  const [searchValue, setSearchValue] = useState('');

  return (
    <>
      <PageTitle>Dự án</PageTitle>
      <BaseCard>
        <BaseRow gutter={{ xs: 10, md: 15, xl: 30 }}>
          <BaseCol span={24}>
            <BaseButtonsForm.Item>
              <BaseButtonsForm.Title>Danh sách dự án</BaseButtonsForm.Title>
            </BaseButtonsForm.Item>
            <DivStyled>
              <SearchInput
                placeholder="Nhập tên dự án"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Link to={'/projects/add'}>Thêm dự án</Link>
            </DivStyled>
            <ProjectTable searchValue={searchValue} />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </>
  );
};

export default ProjectListPage;
